<template>
  <div class="lsg-cabinet">
    <div class="modal" style="align-items: flex-start" v-if="showModal">
      <div class="modal__back" @click="showModal = false"></div>
      <div class="modal__body" style="height: 500px; width: 500px; flex-direction: column; justify-content: space-between">
        <div class="modal__attention" style="height: 100%; width: 100%">
          <span class="modal__attention__title">Настройки фильтров по отчёту</span>
          <div class="modal__attention__container" style="height: 100%; width: 100%; overflow: auto">
            <SelectComponent
              v-model="modelGroups"
              :multiple="true"
              :options="filterGroups"
              label-name="name"
              title="Выберите подразделения"
              style="margin-bottom: 10px"
            />
            <SelectComponent
              v-model="modelUsers"
              :multiple="true"
              :options="filterUsers"
              label-name="name"
              title="Выберите сотрудников"
              style="margin-bottom: 10px"
            />
            <SelectComponent
              v-model="modelWidgets"
              :multiple="true"
              :options="filterWidgets"
              label-name="title"
              title="Выберите виджеты"
              style="margin-bottom: 10px"
            />
            <SelectComponent
              v-model="modelPeriod"
              :multiple="false"
              :options="filterPeriod"
              label-name="title"
              title="Выберите период"
              style="margin-bottom: 10px"
            />
            <div style="color: #7a7a7a">
              <span>Рассылка по отчёту происходит раз в неделю. Если в фильтре ничего не выбрано, рассылка будет по умолчанию, как на рабочем столе. Например, если не выбраны виджеты, то отчёт будет по всем виджетам</span>
            </div>
            <!-- <v-select
              style="margin: 0; height: 60px"
              appendToBody
              :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
              placeholder="Выберите менеджера"
            >
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select> -->
          </div>
        </div>
        <div class="lk-modal-buttons">
          <button class="btn btn--gray" @click="showModal = false">Закрыть</button>
        </div>
      </div>
    </div>
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <router-link v-if="$store.state.me.role === 'admin'" :to="{name: 'admin', hash: '#carousel'}" class="btn" >
          <i class="main__header__settings">
            <NewSettingsIcon />
          </i>
        </router-link>
      </div>
    </div>
    <div v-if="$store.state.me.role !== 'partner'" class="main__content">
      <form class="lk" autocomplete="off">
        <div class="lk__inputs">
          <div class="lk__avatar">
            <div class="lk__avatar__image">
              <img :src="avatar.image" v-if="avatar.image" alt="Аватар"/>
              <img v-else :src="`${baseUrl}/avatar?user=${this.$store.state.me._id}`" alt="user">
              <!-- <LkUser v-else/> -->
            </div>
            <div class="avatar">
              <vue-dropzone
                ref="avatar"
                id="avatar"
                @vdropzone-thumbnail="thumbGenerated"
                @vdropzone-files-added="filePicked"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div class="lk__dz">
                  <i>
                    <PaperClip/>
                  </i>
                  <span>{{ avatar.name ? avatar.name : 'Выбрать файл' }}</span>
                </div>
              </vue-dropzone>
              <div v-if="file" style="margin-top: 10px" class="btn" @click="submitDropFile">Сохранить</div>
            </div>
          </div>
          <div class="lk__field">
            <label for="phone">Номер телефона МТС:</label>
            <input
              type="phone"
              id="phone"
              placeholder="Введите последние 10 цифр номер телефона"
              maxlength="10"
              minlength="10"
              v-model="phone"
              @change="$store.state.me.phone = phone"
            >
          </div>
          <div class="lk__field">
            <label for="abonentId">ID абонента:</label>
            <input type="abonentId" id="abonentId" v-model="$store.state.me.abonentId" readonly>
          </div>
          <div class="lk__field">
            <label for="telegramId">Telegram ID:
              <a v-if="$store.state.me.telegramId && $store.state.me.telegramId.length > 0" @click="deleteTgId" style="cursor: pointer"> Отвязать</a>
              <a v-else href="https://t.me/MailerLeasingTradeBot" target="_blank"> Как привязать аккаунт?</a>
            </label>
            <input type="telegramId" id="telegramId" v-model="telegramId" @change="$store.state.me.telegramId = telegramId">
          </div>
          <div class="lk__field">
            <label for="workphone">Внутренний номер:</label>
            <input v-model="workphone" type="tel" id="workphone" maxlength="4" minlength="3" @change="$store.state.me.workphone = workphone">
          </div>
          <button v-if="isValidate" class="btn" @click="updateUser">Сохранить</button>
        </div>
        <div class="lk__back">
          <LkBack/>
        </div>
      </form>
      <div class="settings lk" style="margin-top: 20px; display: block">
        <div class="lk__inputs" style="width: 100%">
          <h3>Настройки уведомлений</h3>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :sort-options="{
              enabled: false,
            }"
            style="width: 100%"
            compactMode
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'title'">
                {{ props.row.title }}
                <i v-if="props.row.title.includes('Регулярный отчёт') && (settings.tg_report.checked || settings.email_report)" class="report-settings" @click="showModal = true"><NewSettingsIcon /></i>
                <div v-if="props.row.title.includes('Регулярный отчёт')"><a style="cursor: pointer" @click="sendReport">Отправить отчёт</a></div>
              </div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'telegram' && props.row.title === 'Задачи' && $store.state.me.telegramId && $store.state.me.telegramId.length > 0"><input type="checkbox" v-model="settings.tg_tasks"></div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'telegram' && props.row.title === 'Изменение статуса сделки' && $store.state.me.telegramId && $store.state.me.telegramId.length > 0"><input type="checkbox" v-model="settings.tg_statusLeads"></div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'telegram' && props.row.title.includes('Регулярный отчёт') && $store.state.me.telegramId && $store.state.me.telegramId.length > 0"><input type="checkbox" v-model="settings.tg_report.checked"></div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'telegram' && props.row.title === 'ЭДО: подписан контрагентом' && $store.state.me.telegramId && $store.state.me.telegramId.length > 0"><input type="checkbox" v-model="settings.tg_edoNotification"></div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'email' && props.row.title === 'Задачи'"><input type="checkbox" v-model="settings.email_tasks"></div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'email' && props.row.title === 'Изменение статуса сделки'"><input type="checkbox" v-model="settings.email_statusLeads"></div>
              <div class="setting-checkbox" v-else-if="props.column.field === 'email' && props.row.title.includes('Регулярный отчёт')"><input type="checkbox" v-model="settings.email_report"></div>
              <div class="setting-checkbox" v-else-if="props.column.field !== 'title'"><input type="checkbox" disabled></div>
            </template>
          </vue-good-table>
          <!-- <label class="settings__lazy-load">
            <span v-tooltip="'Ленивая загрузка комментариев (тестируется)'">Оптимизация загрузки страниц</span>
            <SwitcherAlt
              :value="settings.lazy_load"
              @updateTable="updateLazyload"
            />
          </label> -->
          <div class="btn" style="margin-top: 20px" @click="updateUserSettings">Сохранить настройки уведомлений</div>
        </div>
      </div>
      <div class="settings lk" style="margin-top: 20px; display: block">
        <div class="lk__inputs" style="width: 100%">
          <h3>Прочие настройки</h3>
          <label class="settings__lazy-load">
            <span v-tooltip="'Доступен функционал, который в разработке'">Режим тестирования</span>
            <SwitcherAlt
              :value="$store.state.me.tester"
              @updateTable="setTester"
            />
          </label>
          <label style="margin-top: 20px">
            <button class="btn" @click="sendTelegramMessage">Отправить заявку на интеграцию с почтой</button>
          </label>
        </div>
      </div>
    </div>
    <div v-else class="main__content">
      <div class="lk lk--avatar">
        <div class="lk__inputs lk__inputs--avatar">
          <div class="lk__avatar">
            <div class="lk__avatar__image">
              <img :src="avatar.image" v-if="avatar.image" alt="Аватар"/>
              <img v-else :src="`${baseUrl}/avatar?user=${this.$store.state.me._id}`" alt="user">
              <!-- <LkUser v-else/> -->
            </div>
            <div class="avatar">
              <vue-dropzone
                ref="avatar"
                id="avatar"
                @vdropzone-thumbnail="thumbGenerated"
                @vdropzone-files-added="filePicked"
                :includeStyling="false"
                useCustomSlot
                :options="dropzoneOptions">
                <div class="lk__dz">
                  <i>
                    <PaperClip/>
                  </i>
                  <span>{{ avatar.name ? avatar.name : 'Выбрать файл' }}</span>
                </div>
              </vue-dropzone>
              <div v-if="file" style="margin-top: 10px" class="btn" @click="submitDropFile">Сохранить</div>
            </div>
          </div>
        </div>
      </div>
      <ApolloQuery
        :query="require('@/graphql/queries/AllContracts.gql')"
        :variables="{}"
        @result="doneLoadingContracts"
        class="lk__inputs"
      >
        <template slot-scope="{ result: { error }}">
          <div v-if="loadingContacts && !error" class="messages__status loading">Идёт загрузка данных...</div>
          <div v-if="error" class="messages__status error">{{ error.message }}</div>
          <form v-if="!loadingContacts && !error" class="lk--client" autocomplete="off">
            <h2>Контактные лица</h2>
            <div class="cabinet__list--client">
              <a href="#"
                class="cabinet__item--client"
                v-for="(item, index) in contacts"
                :key="index"
              >
                <a
                  class="cabinet__item--client__state"
                  @click="
                    showContactDataModal(
                      item.company.id,
                      item,
                      'update'
                    )
                  "
                >
                  <NewSettingsIcon />
                </a>
                <h3 class="cabinet__item--client__name">{{ item.name || 'ФИО не указаны' }}</h3>
                <div class="cabinet__item--client__contact">
                  <span>Компания: {{ item.company.name || 'Не указано' }}</span>
                  <span>Должность: {{ item.post || 'Не указана' }}</span>
                  <span>Email: {{ item.email || 'Не указан' }}</span>
                  <span>Телефон: {{ item.phone[0] || 'Не указан' }}</span>
                </div>
              </a>
            </div>
          </form>
        </template>
      </ApolloQuery>
      <h2 class="accordion" style="display: inline-block; margin-left: 40px" v-if="!loadingPartner && !error">Реквизиты организации</h2>
      <ApolloQuery
        :query="require('@/graphql/queries/PartnerForInn.gql')"
        :variables="{}"
        @result="doneLoadingPartner"
        style="display: flex; margin: 0 40px; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: flex-start"
      >
        <template slot-scope="{ result: { error }}">
          <div v-if="loadingPartner && !error" class="messages__status loading">Идёт загрузка данных...</div>
          <div v-if="error" class="messages__status error">{{ error.message }}</div>
          <form v-if="!loadingPartner && !error" class="lk--client" autocomplete="off">
            <div class="cabinet__list--client">
              <a href="#"
                class="cabinet__item--client"
              >
                <a
                  class="cabinet__item--client__state"
                  @click="showCompanyModal = true"
                >
                  <NewSettingsIcon />
                </a>
                <h3 class="cabinet__item--client__name">{{ companyData.name }}</h3>
                <div class="cabinet__item--client__contact">
                  <span>Компания: {{companyData.fullname || 'Не указано' }}</span>
                  <span>ИНН: {{ companyData.inn || 'Не указан' }}</span>
                  <span>Email: {{ companyData.email || 'Не указан' }}</span>
                  <span>Телефон: {{ companyData.phone || 'Не указан' }}</span>
                </div>
              </a>
              <a href="#"
                class="cabinet__item--client"
              >
                <a
                  class="cabinet__item--client__state"
                  @click="showContactModal = true"
                >
                  <NewSettingsIcon />
                </a>
                <h3 class="cabinet__item--client__name">{{ companyData.name }}</h3>
                <div class="cabinet__item--client__contact">
                  <span>ФИО: {{ contactData.name ? `${contactData.surname} ${contactData.name} ${contactData.patron}` : 'Не указаны' }}</span>
                  <span>Должность: {{ contactData.post || 'Не указана' }}</span>
                  <span>Email: {{ contactData.email || 'Не указан' }}</span>
                  <span>Телефон: {{ contactData.phone || 'Не указан' }}</span>
                </div>
              </a>
            </div>
          </form>
          <div class="modal" style="align-items: flex-start" v-if="showCompanyModal">
            <div class="modal__back" @click="showCompanyModal = false"></div>
            <div class="modal__body">
              <div class="apply__body__form cabinet__item--client">
                <div class="apply__body__title">
                  <h3>Контрагент</h3>
                  <i @click="showCompanyModal = false" class="sup__request__toggle fas fa-times" style="color: #a9a9a9"></i>
                </div>
                <div style="width: 100%" class="apply__body__counterparty _row">
                  <div class="_col-2">
                    <label class="apply__body__item">
                      <span>Наименование:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.name">
                    </label>
                    <label class="apply__body__item">
                      <span>Полное наименование:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.fullname">
                    </label>
                    <label class="apply__body__item">
                      <span>ИНН:</span>
                      <input disabled type="number" class="apply__body__item__input" :value="companyData.inn">
                    </label>
                    <label class="apply__body__item">
                      <span>КПП:</span>
                      <input disabled type="number" class="apply__body__item__input" :value="companyData.kpp">
                    </label>
                    <label class="apply__body__item">
                      <span>Банк:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.bank">
                    </label>
                    <label class="apply__body__item">
                      <span>БИК:</span>
                      <input disabled type="number" class="apply__body__item__input" :value="companyData.bik">
                    </label>
                    <label class="apply__body__item">
                      <span>Расчетный счет:</span>
                      <input disabled type="number" class="apply__body__item__input" :value="companyData.rs">
                    </label>
                  </div>
                  <div class="_col-2">
                    <label class="apply__body__item">
                      <span>Корреспондентский счет:</span>
                      <input disabled type="number" class="apply__body__item__input" :value="companyData.ks">
                    </label>
                    <label class="apply__body__item">
                      <span>Юридический адрес:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.adresU">
                    </label>
                    <label class="apply__body__item">
                      <span>Фактический адрес:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.adresF">
                    </label>
                    <label class="apply__body__item">
                      <span>Почтовый адрес:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.adresP">
                    </label>
                    <label class="apply__body__item">
                      <span>Телефон:</span>
                      <input disabled type="number" class="apply__body__item__input" :value="companyData.phone">
                    </label>
                    <label class="apply__body__item">
                      <span>Email:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="companyData.email">
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" style="align-items: flex-start" v-if="showContactModal">
            <div class="modal__back" @click="showContactModal = false"></div>
            <div class="modal__body">
              <div class="apply__body__form cabinet__item--client">
                <div class="apply__body__title">
                  <h3>Данные руководителя организации</h3>
                  <i @click="showContactModal = false" class="sup__request__toggle fas fa-times" style="color: #a9a9a9"></i>
                </div>
                <div style="width: 100%" class="apply__body__contact">
                  <div style="width: 100%" class="apply__body__contact__content">
                    <label class="apply__body__item">
                      <span>ФИО:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="`${contactData.surname} ${contactData.name} ${contactData.patron}`">
                    </label>
                    <div class="_row _row--apply">
                      <label class="apply__body__item _col-3">
                        <span>Дата рождения:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.birthday ? new Date(contactData.birthday).toLocaleDateString() : ''">
                      </label>
                      <label class="apply__body__item _col-3">
                        <span>Должность:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.post">
                      </label>
                      <label class="apply__body__item _col-3">
                        <span>Основа полномочий:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.basis">
                      </label>
                    </div>
                    <label class="apply__body__item">
                      <span>Место рождения:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="contactData.birthplace">
                    </label>
                    <div class="_row _row--apply">
                      <label class="apply__body__item _col-2">
                        <span>Серия и номер паспорта:</span>
                        <input disabled type="number" class="apply__body__item__input" :value="contactData.passportId">
                      </label>
                      <label class="apply__body__item _col-2">
                        <span>Дата выдачи паспорта:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.passportDate ? new Date(contactData.passportDate).toLocaleDateString() : ''">
                      </label>
                    </div>
                    <label class="apply__body__item">
                      <span>Место выдачи паспорта:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="contactData.passportPlace">
                    </label>
                    <div class="_row _row--apply">
                      <label class="apply__body__item _col-2">
                        <span>Код подразделения:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.passportCode">
                      </label>
                      <label class="apply__body__item _col-2">
                        <span>ИНН:</span>
                        <input disabled type="number" class="apply__body__item__input" :value="contactData.inn">
                      </label>
                    </div>
                    <label class="apply__body__item">
                      <span>Адрес для информирования:</span>
                      <input disabled type="text" class="apply__body__item__input" :value="contactData.adresD">
                    </label>
                    <div class="_row _row--apply _row">
                      <label class="apply__body__item _col-2">
                        <span>Телефон мобильный:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.phone">
                      </label>
                      <label class="apply__body__item _col-2">
                        <span>E-mail:</span>
                        <input disabled type="text" class="apply__body__item__input" :value="contactData.email">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import LkBack from '@/components/svg/LkBack'
// import LkUser from '@/components/svg/LkUser'
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import UPDATE_USER from '../../graphql/mutations/UpdateUser.gql'
import SET_TESTER from '@/graphql/mutations/SetTester.gql'
import ALL_CLIENT_CONTACTS from '../../graphql/queries/AllClientContacts.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import NewSettingsIcon from '@/components/svg/new/Settings'
import SwitcherAlt from '@/components/ui/SwitcherAlt'
import UPDATE_SETTINGS from '../../graphql/mutations/UpdateSettings.gql'
import SelectComponent from '@/components/ui/select'

const _graphQlHelper = new GraphQLHelper()

const BASE_URL = process.env.VUE_APP_HTTP

export default {
  name: 'Cabinet',
  components: {
    PaperClip,
    // LkUser,
    SelectComponent,
    LkBack,
    vueDropzone: vue2Dropzone,
    NewSettingsIcon,
    SwitcherAlt
  },
  data () {
    return {
      // test: [{ title: 123 }, { title: 456 }, { title: 789 }],
      // filterGroups: [],
      // filterUsers: [],
      filterPeriod: [
        // { title: 'За сегодня' },
        // { title: 'За вчера' },
        { title: 'За неделю' },
        { title: 'За текущий месяц' },
        // { title: 'За предыдущий месяц' },
        { title: 'За текущий год' }
      ],
      filterWidgets: [
        { title: 'Исходящих звонков' },
        { title: 'Исходящие звонки(МТС)' },
        { title: 'Всего заявок' },
        { title: 'Переданных заявок' },
        { title: 'Задач в работе' },
        { title: 'Просрочено задач' },
        { title: 'План' },
        { title: 'Выполнено' },
        { title: 'Остаток' },
        { title: 'Лимит количество' },
        { title: 'Заведено проектов' },
        { title: 'Проектов на рассмотрении' },
        { title: 'Одобрено' },
        { title: 'Передано в лизинг' },
        { title: 'Кол-во договоров' },
        { title: 'Отказ СБ' },
        { title: 'Забытые клиенты' },
        { title: 'Не создано' },
        { title: 'Не выгружено' },
        { title: 'Не подписано' },
        { title: 'Нет аванса' }
      ],
      selectedPeriod: null,
      selectedGroups: this.$store.state.me.settings.tg_report.filter.groups || [],
      selectedUsers: this.$store.state.me.settings.tg_report.filter.users || [],
      selectedWidgets: this.$store.state.me.settings.tg_report.filter.users.widgets || [],
      showModal: false,
      settings: {
        lazy_load: null,
        email_tasks: this.$store.state.me.settings.email_tasks || false,
        email_report: this.$store.state.me.settings.email_report || false,
        email_statusLeads: this.$store.state.me.settings.email_statusLeads || false,
        email_edoNotification: this.$store.state.me.settings.email_edoNotification || false,
        tg_tasks: this.$store.state.me.settings.tg_tasks || false,
        tg_statusLeads: this.$store.state.me.settings.tg_statusLeads || false,
        tg_report: this.$store.state.me.settings.tg_report || { checked: false, period: '', filter: { widgets: [], groups: [], users: [] } },
        tg_edoNotification: this.$store.state.me.settings.tg_edoNotification || false
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 120,
        maxFiles: 1,
        maxFilesize: 2, // 2MB
        acceptedFiles: 'image/png, image/jpg, image/jpeg'
      },
      avatar: {
        image: null,
        name: null
      },
      columns: [
        {
          label: 'Основные',
          field: 'title'
        },
        {
          label: 'Telegram',
          field: 'telegram',
          width: '100px'
        },
        {
          label: 'Email',
          field: 'email',
          width: '100px'
        }
      ],
      rows: [
        {
          title: 'Задачи',
          email: false,
          telegram: false
        },
        {
          title: 'Изменение статуса сделки',
          email: false,
          telegram: false
        },
        {
          title: 'Регулярный отчёт (каждый четверг)',
          email: false,
          telegram: false
        },
        {
          title: 'ЭДО: подписан контрагентом',
          email: false,
          telegram: false
        }
      ],
      file: null,
      isValidate: false,
      telegramId: this.$store.state.me.telegramId || '',
      phone: this.$store.state.me.phone || '',
      workphone: this.$store.state.me.workphone || '',
      title: 'Личный кабинет',
      cabinetlist: [],
      contacts: [],
      loadingContacts: true,
      loadingPartner: true,
      baseUrl: process.env.VUE_APP_HTTP,
      companyData: null,
      contactData: null,
      expandedCompany: false,
      showCompanyModal: false,
      showContactModal: false
    }
  },
  created () {
    this.settings.lazy_load = this.$store.state.me.settings.lazy_load
    this.phone = this.$store.state.me.phone
    this.$store.state.data.trades.contactDataModal = false
  },
  beforeUpdate () {
    this.isValidate = this.checkValidatePhone(this.phone) || this.checkValidateWorkphone(this.workphone) || (this.telegramId.length > 0)
  },
  computed: {
    modelGroups: {
      get () {
        try {
          const result = []
          this.filterGroups.forEach(elem => {
            this.$store.state.me.settings.tg_report.filter.groups.forEach(item => {
              if (elem.id === item) result.push(elem)
            })
          })
          return result
        } catch (e) {
          return []
        }
      },
      set (value) {
        this.selectedGroups = value
        // this.selectedGroups = value.map(elem => elem.id)
        this.settings.tg_report.filter.groups = value.map(elem => elem.id)
        this.$store.state.me.settings.tg_report.filter.groups = value.map(elem => elem.id)
      }
    },
    modelUsers: {
      get () {
        try {
          const result = []
          this.filterUsers.forEach(elem => {
            this.$store.state.me.settings.tg_report.filter.users.forEach(item => {
              if (elem.id === item || elem._id === item) result.push(elem)
            })
          })
          return result
        } catch (e) {
          return []
        }
      },
      set (value) {
        this.selectedUsers = value
        this.settings.tg_report.filter.users = value.map(elem => (elem.id || elem._id))
        this.$store.state.me.settings.tg_report.filter.users = value.map(elem => (elem.id || elem._id))
      }
    },
    modelWidgets: {
      get () {
        try {
          const result = []
          this.filterWidgets.forEach(elem => {
            this.$store.state.me.settings.tg_report.filter.widgets.forEach(item => {
              if (elem.title === item) result.push(elem)
            })
          })
          return result
        } catch (e) {
          return []
        }
      },
      set (value) {
        this.selectedUsers = value
        // this.selectedGroups = value.map(elem => elem.id)
        this.settings.tg_report.filter.widgets = value.map(elem => elem.title)
        this.$store.state.me.settings.tg_report.filter.widgets = value.map(elem => elem.title)
      }
    },
    modelPeriod: {
      get () {
        try {
          return { title: this.$store.state.me.settings.tg_report.period }
        } catch (e) {
          return null
        }
      },
      set (value) {
        this.selectedPeriod = value.title
        this.settings.tg_report.period = value.title
        this.$store.state.me.settings.tg_report.period = value.title
      }
    },
    filterGroups: {
      get: function () {
        const groups = this.$store.state.allGroups
        const me = this.$store.state.me
        if (me.role === 'admin') {
          return groups
        } else if (me.role === 'headmanager') {
          return groups.filter(group => group.id === me.group.id)
        }
        return []
      }
    },
    filterUsers: {
      get: function () {
        const users = this.$store.state.allUsers.filter(user => !user.banned)
        const me = this.$store.state.me
        if (me.role === 'admin') {
          return users
        } else if (me.role === 'headmanager') {
          return users.filter(user => {
            if (user.group) return user.group.id === me.group.id
          })
        }
        return [me]
      }
    }
  },
  methods: {
    async sendReport () {
      await axios.get(`${BASE_URL}/sendReport`, {
        params: {
          responsible_user_id: this.$store.state.me._id
        }
      })
    },
    deleteTgId () {
      this.telegramId = ''
      this.$store.state.me.telegramId = ''
      this.updateUser()
    },
    updateUserSettings () {
      this.$store.state.me.settings = this.settings
      this.$store.state.me.settings.lazy_load = this.settings.lazy_load
      this.$apollo.mutate({
        mutation: UPDATE_SETTINGS,
        variables: {
          settings: this.$store.state.me.settings
        },
        update: (store, { data }) => {
          if (data) {
            this.$store.state.me.settings = data.updateSettings.settings
            this.$notify({
              group: 'lsg-notify',
              text: 'Сохранено'
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async sendTelegramMessage () {
      const url = `${BASE_URL}/api/mail/integration?email=${this.$store.state.me.email}`
      const message = `${this.$store.state.me.name} (${this.$store.state.me.email}) запрашивает привязку почты к СРМ`

      await axios.post(url, { message }, {
        headers: {
          Authorization: `Bearer ${localStorage['apollo-token']}`
        }
      })
        .then(res => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Успешно'
          })
        }
        ).catch((e) => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Ошибка'
          })
          throw new Error(e)
        })
    },
    setTester (value) {
      this.$apollo.mutate({
        mutation: SET_TESTER,
        variables: {
          value: value
        },
        update: (store, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Сохранено'
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    showContactDataModal (companyId, data, type) {
      this.$store.state.data.cabinet.contactDataModal = !this.$store.state.data
        .cabinet.contactDataModal
      this.$store.state.data.cabinet.contactDataModalProps.companyId = companyId
      this.$store.state.data.cabinet.contactDataModalProps.data = data
      this.$store.state.data.cabinet.contactDataModalProps.type = type
    },
    doneLoadingContracts ({ data, loading }) {
      let ids = data.allContracts.map(element => {
        element = element.partner.id
        return element
      })
      ids = [ ...new Set(ids) ]
      this.getContacts(ids)
    },
    doneLoadingPartner ({ data, loading }) {
      this.companyData = data.PartnerForInn.Kompany[0]
      this.contactData = data.PartnerForInn.Kontact[0]
      this.loadingPartner = false
    },
    checkValidatePhone (value) {
      return (/^[9]\d{9}$/).test(value)
    },
    checkValidateWorkphone (value) {
      return (/^\d{3,4}$/).test(value)
    },
    getContacts (companyIds) {
      this.$apollo.mutate({
        mutation: ALL_CLIENT_CONTACTS,
        variables: {
          companyIds
        },
        update: (store, { data }) => {
          this.loadingContacts = false
          if (data) {
            this.contacts = data.allClientContacts
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    updateUser () {
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          id: this.$store.state.me._id,
          phone: this.phone.substr(-10, 10),
          workphone: this.workphone,
          telegramId: this.telegramId
        },
        update: (_, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Данные успешно изменены.'
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
      this.phone = ''
      this.workphone = ''
    },
    thumbGenerated (e, dataUrl) {
      this.avatar.name = e.name
      this.avatar.image = dataUrl
    },
    filePicked (e) {
      this.file = e[0]
    },
    submitDropFile () {
      const url = `${BASE_URL}/avatar`
      let formData = new FormData()
      formData.append('user', this.$store.state.me._id)
      formData.append('filename', this.file.upload.uuid)
      formData.append('avatar', this.file)
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(res => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Успешно'
        })
        this.$router.go()
      }
      ).catch((e) => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка'
        })
        throw new Error(e)
      })
    }
  }
}
</script>

<style lang="stylus">

.accordion
  display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding 40px 80px 40px 40px
    width 100%
    margin 8px 0
    position relative
    cursor pointer

    &:hover
      box-shadow: 0 10px 30px rgba($darker, 0.17);

    &__state
      absolute right 40px top 40px bottom
      width 24px
      height 24px
      display flex
      align-items center
      justify-content center
      flex-shrink 0

      svg
        width 100%
        height 100%

        path
          stroke $c7A
    &__title
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin 0
      color $orange

      +below(470px)
        font-size 14px

      +below(375px)
        font-size 12px

    &__form
      width 100%
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      margin-top 20px
      color $dark
      white-space pre-wrap

      > a
        color $orange
        z-index 2

        &:hover
          text-shadow 1px 1px 2px $orange

      +below(470px)
        font-size 14px

      +below(375px)
        font-size 12px

      ^[0]:hover
        color $dark

.setting-checkbox {
  padding-left 25px
}

.report-settings {
  width 20px
  height 20px
  display flex
  align-items center
  justify-content center
  cursor pointer

  svg {
    width 100%
    height 100%
    fill #7a7a7a !important
  }
}

.avatar {
  display flex
  flex-direction column
}

.main__header__settings {
  width 20px
  height 20px
  display flex
  align-items center
  justify-content center

  svg {
    width 100%
    height 100%
    fill $white
  }
}

.messages__status
  width 100%

.lk--client
  width 100%

.lk--avatar {
  background none !important
}

.lk__inputs--avatar {
  margin-bottom 0 !important
  padding-bottom 0 !important
}

.settings {
  &__lazy-load {
    display flex
    flex-direction row
    align-items center

    > span {
      margin-right 20px
    }
  }
}
.lk-modal-buttons {
  display grid
  grid-template-columns repeat(3, 1fr)
  gap 16px
  width 100%

  button {
    font-size: 15px;
    line-height: 22px;
    text-transform uppercase
  }
}
.cabinet
  &__bar
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap

    +below(1040px)
      justify-content center

    &__links {
      display flex
      justify-content space-between
      width 400px
      margin-bottom 20px
    }

    &__input
      max-width 500px
      width 100%
      margin 0 0 20px 0
      position relative

      a
        display flex
        align-items center
        absolute right 16px top bottom

  &__list
    display flex
    flex-direction column
    align-items flex-start
    margin -8px 0

  &__list--client
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 15px

    +below(1600px)
      grid-template-columns repeat(2, 1fr)

    +below(970px)
      grid-template-columns repeat(1, 1fr)

  &__item--client
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding 40px 60px 40px 40px
    width 100%
    margin 8px 0
    position relative

    &_active
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      background: $white;
      border: 1px solid rgba($darkgray, 0.3);
      box-sizing: border-box;
      border-radius: 10px;
      padding 40px
      width 100%
      margin 8px 0
      position relative
      max-height auto

    &:hover
      box-shadow: 0 10px 30px rgba($darker, 0.17);

    &__state
      absolute right 40px top 40px bottom
      width 24px
      height 24px
      display flex
      align-items center
      justify-content center
      flex-shrink 0

      svg
        width 100%
        height 100%
        fill $dark
        transition fill 0.3s

      &:hover
        svg
          fill $orange

    &__name
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin 0
      color $orange

    &__contact
      display flex
      flex-direction column
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-top 20px
      color $dark

      ^[0]:hover
        color $dark

  &__answer
    color $black

.apply {
  display flex
  justify-content center
  flex-direction column
  align-items center

  &__body {
    display flex
    flex-direction column
    align-items center
    padding 32px
    width 90%
    max-width 1050px
    background $white
    border 1px solid $pinky
    box-sizing border-box
    border-radius 15px
    margin-top 15px
    box-shadow alpha($dark, 20%) 0 30px 60px

    +below(1140px) {
      &__contact {
        &__content {
          width 100% !important
        }
        &__back {
          display none !important
        }
      }
    }

    &__counterparty {
      display flex
      flex-direction row
      padding 0 !important
    }

    &__contact {
      display flex
      flex-direction row
      justify-content space-between

      &__content {
        display flex
        flex-direction column
        width 70%
      }

      &__back {
        display flex
        align-items center
      }
    }

    &__end {
      display flex
      flex row nowrap

      +below(680px) {
        flex-direction column-reverse
        align-items center
      }

      &__icon {
        display block
        min-width 176px
      }

      &__content {
        margin-left 20px
        display flex
        flex-direction column
        align-items flex-start
        justify-content center

        > span > a {
          color $blue
        }
      }
    }

    &__form {
      width 100%
    }

    &__item {
      display block
      width 100%
      margin-bottom 10px

      > strong
      > span {
        color #7a7a7a

        > a {
          cursor pointer
        }
      }

      &__input {
        margin-top 10px
      }
    }

    &__minimized {
      display flex
      flex-direction column
      align-items center
      padding 15px
      background #ff8000
      border 1px solid $pinky
      box-sizing border-box
      box-shadow none
      border-radius 10px
      width auto
      z-index 1000
    }
  }

  &__header {
    display flex
    flex-direction column
    padding 15px
    background $white
    border 1px solid $pinky
    box-sizing border-box
    box-shadow none
    border-radius 15px
    align-content center
    align-self center
    min-width 900px
    width 59.5%
    +below(1140px) {
      width 90%
      min-width auto
      .bn--client__header__textitem {
        display none
      }
    }

    &__item
    &__text {
      display flex
      flex-direction row
      width 100%
      justify-content space-between
    }
  }

  &__title {
    font-weight bold
    font-size 15px
    width 100%

    .fa-chevron-up
    .fa-chevron-down {
      transition opacity 0.25s
      font-size 1.25rem
      color darkgray
      cursor pointer
      margin-right 1rem
      float right

      &:hover {
        opacity 0.7
      }
    }

    .fa-times {
      transition opacity 0.25s
      font-size 1.25rem
      color darkgray
      cursor pointer
      float right

      &:hover {
        opacity 0.7
      }
    }
    .bn__title {
      transition opacity 0.25s
      color gray
      cursor pointer
      margin-right 2rem

      &:hover {
        opacity 0.7
      }
    }
  }
}
</style>

<template>
  <svg width="422" height="320" viewBox="0 0 422 320" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5" clip-path="url(#clip0)">
      <path
        d="M368.796 83.6796C369.113 80.8559 369.279 77.985 369.279 75.0782C369.279 73.5439 369.23 72.0209 369.14 70.5091L354.926 65.9108C354.053 59.9174 352.332 54.2004 349.895 48.8788L359.32 37.2808C357.141 33.444 354.641 29.8138 351.854 26.4285L337.638 31.0673C333.494 26.8351 328.761 23.1848 323.566 20.2488L324.375 5.31267C320.408 3.50658 316.26 2.02847 311.967 0.914273L303.189 13.0289C299.769 12.4539 296.256 12.1484 292.671 12.1484C290.227 12.1484 287.817 12.2921 285.445 12.564L277.321 0C272.968 0.88507 268.748 2.14079 264.696 3.73122L264.714 18.6898C259.361 21.3495 254.442 24.746 250.089 28.7513L236.143 23.369C233.18 26.6083 230.489 30.0991 228.113 33.8124L236.913 45.8956C234.189 51.0914 232.17 56.7119 230.981 62.6355L216.548 66.4768C216.231 69.3005 216.065 72.1714 216.065 75.0782C216.065 76.6125 216.114 78.1355 216.204 79.6473L230.418 84.2456C231.294 90.2389 233.012 95.956 235.449 101.278L226.024 112.876C228.203 116.712 230.703 120.343 233.49 123.728L247.706 119.089C251.848 123.321 256.583 126.974 261.778 129.908L260.969 144.844C264.936 146.65 269.084 148.128 273.377 149.242L282.155 137.127C285.575 137.703 289.088 138.008 292.673 138.008C295.117 138.008 297.527 137.864 299.899 137.592L308.025 150.154C312.378 149.269 316.599 148.013 320.651 146.423L320.633 131.464C325.985 128.805 330.904 125.408 335.257 121.403L349.201 126.785C352.164 123.546 354.855 120.055 357.231 116.342L348.431 104.259C351.155 99.0627 353.177 93.4423 354.363 87.5186L368.796 83.6796ZM292.671 105.128C276.077 105.128 262.622 91.6744 262.622 75.0782C262.622 58.482 276.074 45.0262 292.671 45.0262C309.267 45.0262 322.719 58.4798 322.719 75.0782C322.719 91.6744 309.265 105.128 292.671 105.128Z"
        fill="#9EC8FF"></path>
      <path d="M333.653 109.351C333.4 109.351 333.146 109.266 332.937 109.09C332.461 108.693 332.396 107.985 332.791 107.509C340.342 98.4268 344.502 86.9097 344.502 75.078C344.502 69.4127 343.577 63.8484 341.748 58.5425C341.546 57.9562 341.858 57.316 342.445 57.1138C343.031 56.9116 343.671 57.2239 343.873 57.8102C345.782 63.352 346.75 69.1633 346.75 75.078C346.75 87.4331 342.406 99.4624 334.52 108.947C334.296 109.212 333.975 109.351 333.653 109.351Z" fill="white"></path>
      <path d="M329.303 38.424C329.026 38.424 328.75 38.3229 328.532 38.1185C323.458 33.3404 317.58 29.717 311.062 27.3516C310.478 27.1404 310.177 26.4957 310.388 25.9117C310.599 25.3276 311.244 25.0266 311.828 25.24C318.636 27.711 324.775 31.4917 330.073 36.4808C330.525 36.9054 330.547 37.6175 330.12 38.069C329.9 38.3049 329.601 38.424 329.303 38.424Z" fill="white"></path>
      <path d="M281.016 25.8985C280.513 25.8985 280.057 25.5593 279.929 25.0493C279.776 24.4473 280.142 23.8363 280.742 23.6858C284.971 22.6188 289.349 22.0774 293.751 22.0774C294.371 22.0774 294.874 22.5806 294.874 23.2006C294.874 23.8206 294.371 24.3238 293.751 24.3238C289.533 24.3238 285.342 24.8427 281.292 25.8648C281.2 25.8872 281.108 25.8985 281.016 25.8985Z" fill="white"></path>
      <path
        d="M127.296 273.956C127.514 271.999 127.631 270.014 127.631 267.999C127.631 266.936 127.595 265.88 127.534 264.834L117.689 261.648C117.083 257.497 115.893 253.537 114.203 249.853L120.731 241.819C119.221 239.162 117.49 236.648 115.56 234.303L105.715 237.515C102.845 234.584 99.5656 232.055 95.9695 230.024L96.5288 219.679C93.7818 218.428 90.909 217.406 87.9352 216.633L81.8549 225.023C79.4853 224.624 77.0528 224.415 74.5686 224.415C72.875 224.415 71.2061 224.513 69.5642 224.702L63.9354 216.002C60.9189 216.615 57.9967 217.485 55.1913 218.585L55.2048 228.946C51.4987 230.788 48.0914 233.139 45.0748 235.914L35.4165 232.187C33.3636 234.431 31.5016 236.848 29.8529 239.42L35.9489 247.788C34.0621 251.387 32.6628 255.28 31.8385 259.382L21.841 262.041C21.6231 263.998 21.5063 265.986 21.5063 267.999C21.5063 269.061 21.5423 270.117 21.6029 271.164L31.4476 274.349C32.0541 278.5 33.2445 282.461 34.9336 286.145L28.4064 294.178C29.9158 296.835 31.6476 299.349 33.577 301.694L43.4217 298.482C46.2922 301.413 49.5715 303.943 53.1676 305.974L52.6083 316.318C55.3553 317.569 58.2281 318.591 61.2019 319.364L67.2822 310.974C69.6518 311.374 72.0843 311.583 74.5686 311.583C76.2621 311.583 77.931 311.482 79.5729 311.295L85.2017 319.995C88.2182 319.382 91.1404 318.513 93.9458 317.412L93.9323 307.052C97.6384 305.21 101.046 302.858 104.062 300.084L113.721 303.81C115.774 301.566 117.636 299.149 119.284 296.577L113.188 288.209C115.075 284.611 116.474 280.718 117.299 276.616L127.296 273.956ZM74.5686 288.811C63.0752 288.811 53.7561 279.493 53.7561 267.996C53.7561 256.5 63.0729 247.182 74.5686 247.182C86.0619 247.182 95.381 256.5 95.381 267.996C95.381 279.493 86.0619 288.811 74.5686 288.811Z"
        fill="#9EC8FF"></path>
      <path d="M102.955 292.082C102.701 292.082 102.447 291.997 102.239 291.821C101.762 291.424 101.697 290.716 102.093 290.24C107.272 284.011 110.125 276.113 110.125 267.996C110.125 264.11 109.489 260.294 108.236 256.655C108.034 256.068 108.346 255.428 108.932 255.226C109.518 255.024 110.158 255.336 110.361 255.922C111.695 259.797 112.371 263.859 112.371 267.994C112.371 276.634 109.334 285.042 103.82 291.673C103.595 291.943 103.276 292.082 102.955 292.082Z" fill="white"></path>
      <path d="M99.9405 242.954C99.6643 242.954 99.388 242.853 99.1701 242.648C95.6886 239.371 91.6591 236.886 87.1871 235.265C86.6031 235.053 86.3021 234.409 86.5132 233.825C86.7244 233.241 87.369 232.94 87.953 233.151C92.7125 234.878 97.0049 237.522 100.709 241.011C101.16 241.435 101.183 242.147 100.756 242.599C100.538 242.837 100.239 242.954 99.9405 242.954Z" fill="white"></path>
      <path d="M66.4958 234.279C65.9927 234.279 65.5367 233.939 65.4087 233.429C65.256 232.827 65.6221 232.216 66.2218 232.066C69.1799 231.32 72.2392 230.94 75.3186 230.94C75.9385 230.94 76.4416 231.444 76.4416 232.064C76.4416 232.684 75.9385 233.187 75.3186 233.187C72.4256 233.187 69.5506 233.542 66.7721 234.243C66.68 234.27 66.5857 234.279 66.4958 234.279Z" fill="white"></path>
      <path
        d="M414.673 140.91C414.673 140.331 414.655 139.753 414.626 139.181L422.002 133.257C421.425 130.301 420.52 127.463 419.323 124.784L409.799 124.062C407.634 120.646 404.831 117.674 401.556 115.313L401.524 105.822C398.872 104.431 396.044 103.328 393.079 102.565L386.57 109.571C385.447 109.45 384.308 109.387 383.153 109.387C380.285 109.387 377.507 109.773 374.865 110.49L367.401 104.577C364.585 105.802 361.937 107.34 359.506 109.156L360.919 118.566C358.136 121.335 355.87 124.622 354.271 128.27L344.985 130.422C344.201 133.288 343.73 136.285 343.613 139.374L351.791 144.102C352.206 148.245 353.426 152.149 355.288 155.66L351.193 164.237C352.95 166.638 354.969 168.837 357.211 170.785L365.98 167.35C369.427 169.594 373.34 171.182 377.543 171.937L381.675 180.449C382.167 180.467 382.662 180.48 383.16 180.48C385.638 180.48 388.061 180.251 390.413 179.815L393.2 170.8C397.261 169.437 400.949 167.267 404.083 164.484L413.299 166.555C415.19 164.335 416.838 161.905 418.209 159.301L412.867 151.455C414.035 148.155 414.673 144.608 414.673 140.91ZM382.808 154.8C375.476 154.8 369.531 148.856 369.531 141.521C369.531 134.189 375.474 128.243 382.808 128.243C390.139 128.243 396.084 134.187 396.084 141.521C396.084 148.853 390.139 154.8 382.808 154.8Z"
        fill="#9EC8FF"></path>
      <path d="M97.6476 69.8689H0V229.939H97.6476V69.8689Z" fill="#99BBEB"></path>
      <path d="M94.4378 73.1306H5.11426V223.837H94.4378V73.1306Z" fill="white"></path>
      <path d="M31.8315 133.565C35.194 126.331 40.8834 124.015 50.2272 124.015C61.3522 124.015 67.1292 128.045 68.7666 133.915C48.8436 134.187 31.8315 133.565 31.8315 133.565Z" fill="#9BD6E8"></path>
      <path d="M46.3413 88.8351C41.6312 90.1582 41.8378 92.8044 40.2678 95.2597C38.6978 97.7172 35.2859 99.3548 34.8771 100.992C34.4683 102.63 35.9283 106.042 34.1943 107.954C32.4626 109.866 31.8741 110.82 32.5569 112.73C33.2397 114.641 34.7401 117.643 34.8771 118.051C35.0141 118.46 36.9233 121.327 39.2436 121.735C41.5638 122.144 45.9303 122.827 48.1135 123.782C50.2967 124.737 57.3181 122.56 61.2151 122.695C65.1121 122.832 67.7647 119.961 67.4929 118.049C67.2212 116.137 70.3657 112.316 70.359 109.041C70.35 105.523 66.2643 102.9 65.1727 100.579C64.0811 98.2586 65.3097 94.1635 61.2151 91.7396C58.6275 90.2076 56.8486 90.3424 54.8002 89.6617C52.7562 88.9811 49.3443 87.9904 46.3413 88.8351Z" fill="#3A384D"></path>
      <path d="M52.0734 129.789C51.0087 131.361 50.2271 131.426 49.0703 129.789C48.1517 128.486 46.3413 125.527 46.3413 124.027V109.044C46.3413 107.543 47.5699 106.314 49.0703 106.314H52.0734C53.5738 106.314 54.8024 107.543 54.8024 109.044V124.027C54.8024 125.53 53.028 128.38 52.0734 129.789Z" fill="#F09959"></path>
      <path d="M52.0734 106.314H49.0703C47.5699 106.314 46.3413 107.543 46.3413 109.044V122.412C49.7127 123.485 52.7899 121.351 54.8024 118.948V109.044C54.8024 107.543 53.5738 106.314 52.0734 106.314Z" fill="#E38540"></path>
      <path d="M50.5733 118.833C55.5849 118.833 59.6476 113.297 59.6476 106.467C59.6476 99.6372 55.5849 94.1006 50.5733 94.1006C45.5617 94.1006 41.499 99.6372 41.499 106.467C41.499 113.297 45.5617 118.833 50.5733 118.833Z" fill="#F09959"></path>
      <path d="M54.8026 96.6254C49.0705 106.316 40.6094 105.359 40.6094 105.359C40.6094 105.359 40.8138 92.5303 47.5342 91.8474C54.2545 91.1645 58.2122 93.8624 59.5778 98.3147C60.9435 102.767 59.5778 105.701 59.5778 105.701C59.5778 105.701 57.3946 103.996 56.303 101.264C55.2114 98.5348 54.8026 96.6254 54.8026 96.6254Z" fill="#3A384D"></path>
      <path d="M41.8053 110.537C42.9964 109.971 43.2739 108.064 42.4251 106.276C41.5763 104.489 39.9226 103.498 38.7314 104.064C37.5403 104.63 37.2628 106.538 38.1116 108.325C38.9604 110.113 40.6141 111.103 41.8053 110.537Z" fill="#F09959"></path>
      <path d="M58.3086 106.276C57.4595 108.064 57.738 109.971 58.9285 110.537C60.1189 111.103 61.7743 110.113 62.6233 108.327C63.4724 106.539 63.1939 104.632 62.0034 104.066C60.8107 103.499 59.1576 104.488 58.3086 106.276Z" fill="#F09959"></path>
      <path d="M53.9848 113.175C53.9848 113.175 52.4575 115.156 50.573 115.156C48.6885 115.156 47.1611 113.175 47.1611 113.175H53.9848Z" fill="white"></path>
      <path d="M61.3749 153.185H38.4578C36.9934 153.185 35.7939 151.985 35.7939 150.52C35.7939 149.054 36.9934 147.856 38.4578 147.856H61.3749C62.8394 147.856 64.0388 149.056 64.0388 150.52C64.0411 151.985 62.8417 153.185 61.3749 153.185Z" fill="#99BBEB"></path>
      <path d="M65.5706 161.373H34.262C32.7976 161.373 31.5981 160.173 31.5981 158.708C31.5981 157.242 32.7976 156.044 34.262 156.044H65.5706C67.0351 156.044 68.2345 157.244 68.2345 158.708C68.2368 160.173 67.0373 161.373 65.5706 161.373Z" fill="#99BBEB"></path>
      <path d="M87.9958 190.82H11.8368C10.9856 190.82 10.2915 190.124 10.2915 189.275C10.2915 188.426 10.9878 187.729 11.8368 187.729H87.9981C88.8494 187.729 89.5434 188.426 89.5434 189.275C89.5434 190.124 88.8471 190.82 87.9958 190.82Z" fill="#99BBEB"></path>
      <path d="M87.9958 198.009H11.8368C10.9856 198.009 10.2915 197.312 10.2915 196.463C10.2915 195.612 10.9878 194.917 11.8368 194.917H87.9981C88.8494 194.917 89.5434 195.614 89.5434 196.463C89.5434 197.312 88.8471 198.009 87.9958 198.009Z" fill="#99BBEB"></path>
      <path d="M87.9958 205.197H11.8368C10.9856 205.197 10.2915 204.501 10.2915 203.651C10.2915 202.8 10.9878 202.106 11.8368 202.106H87.9981C88.8494 202.106 89.5434 202.802 89.5434 203.651C89.5434 204.501 88.8471 205.197 87.9958 205.197Z" fill="#99BBEB"></path>
      <path d="M77.3449 135.344H22.488C20.9651 135.344 19.7275 134.106 19.7275 132.583V82.0196C19.7275 80.4965 20.9651 79.2588 22.488 79.2588H77.3449C78.8677 79.2588 80.1054 80.4965 80.1054 82.0196V132.583C80.1054 134.104 78.8677 135.344 77.3449 135.344ZM22.488 81.5029C22.2095 81.5029 21.9737 81.7388 21.9737 82.0173V132.581C21.9737 132.859 22.2095 133.095 22.488 133.095H77.3449C77.6234 133.095 77.8592 132.859 77.8592 132.581V82.0196C77.8592 81.741 77.6234 81.5052 77.3449 81.5052H22.488V81.5029Z" fill="#99BBEB"></path>
      <path d="M30.4055 168.325L31.9935 171.544L35.5446 172.059L32.9751 174.566L33.5815 178.101L30.4055 176.432L27.2273 178.101L27.8337 174.566L25.2642 172.059L28.8153 171.544L30.4055 168.325Z" fill="#99BBEB"></path>
      <path d="M43.4126 168.325L45.0006 171.544L48.5517 172.059L45.9821 174.566L46.5886 178.101L43.4126 176.432L40.2365 178.101L40.843 174.566L38.2734 172.059L41.8245 171.544L43.4126 168.325Z" fill="#99BBEB"></path>
      <path d="M56.4199 168.325L58.0079 171.544L61.5612 172.059L58.9894 174.566L59.5981 178.101L56.4199 176.432L53.2439 178.101L53.8503 174.566L51.2808 172.059L54.8319 171.544L56.4199 168.325Z" fill="#99BBEB"></path>
      <path d="M69.4296 168.325L71.0176 171.544L74.5687 172.059L71.9992 174.566L72.6056 178.101L69.4296 176.432L66.2536 178.101L66.8601 174.566L64.2905 172.059L67.8416 171.544L69.4296 168.325Z" fill="#99BBEB"></path>
      <path d="M315.343 69.8689H217.696V229.939H315.343V69.8689Z" fill="#99BBEB"></path>
      <path d="M312.134 73.1306H222.81V223.837H312.134V73.1306Z" fill="white"></path>
      <path d="M253.77 105.649C253.77 112.82 258.087 124.386 268.269 124.015C278.45 123.645 280.703 112.617 281.096 109.506C281.492 106.395 253.77 105.649 253.77 105.649Z" fill="#7A4E34"></path>
      <path d="M249.527 133.565C252.89 126.332 258.579 124.016 267.923 124.016C279.048 124.016 284.825 128.046 286.462 133.915C266.539 134.187 249.527 133.565 249.527 133.565Z" fill="#D54A41"></path>
      <path d="M269.769 129.789C268.705 131.361 267.923 131.426 266.766 129.789C265.847 128.486 264.037 125.527 264.037 124.027V109.044C264.037 107.543 265.266 106.314 266.766 106.314H269.769C271.27 106.314 272.498 107.543 272.498 109.044V124.027C272.5 125.53 270.726 128.38 269.769 129.789Z" fill="#F09959"></path>
      <path d="M269.769 106.314H266.766C265.266 106.314 264.037 107.543 264.037 109.044V122.412C267.409 123.485 270.486 121.351 272.498 118.948V109.044C272.5 107.543 271.272 106.314 269.769 106.314Z" fill="#E38540"></path>
      <path d="M268.269 118.833C273.281 118.833 277.343 113.297 277.343 106.467C277.343 99.6372 273.281 94.1006 268.269 94.1006C263.258 94.1006 259.195 99.6372 259.195 106.467C259.195 113.297 263.258 118.833 268.269 118.833Z" fill="#F09959"></path>
      <path d="M273.257 97.0659C272.179 100.869 269.816 100.824 266.521 102.073C263.226 103.322 261.652 104.578 260.574 107.011C259.199 110.113 261.198 119.159 261.198 119.159C261.198 119.159 252.854 112.914 253.82 103.095C254.785 93.2741 260.574 89.4709 266.023 89.4709C271.472 89.4709 273.543 92.7911 273.543 92.7911C273.543 92.7911 276.445 92.8023 279.189 96.0551C282.88 100.427 281.743 111.041 279.189 114.844C276.635 118.647 274.933 119.84 274.989 119.669C275.045 119.498 277.372 110.764 276.123 105.364C274.875 99.966 273.257 97.0659 273.257 97.0659Z" fill="#7A4E34"></path>
      <path d="M271.681 113.175C271.681 113.175 270.153 115.156 268.269 115.156C266.384 115.156 264.857 113.175 264.857 113.175H271.681Z" fill="white"></path>
      <path d="M279.073 153.185H256.156C254.689 153.185 253.492 151.985 253.492 150.52C253.492 149.054 254.691 147.856 256.156 147.856H279.073C280.539 147.856 281.737 149.056 281.737 150.52C281.737 151.985 280.537 153.185 279.073 153.185Z" fill="#99BBEB"></path>
      <path d="M283.269 161.373H251.96C250.494 161.373 249.296 160.173 249.296 158.708C249.296 157.242 250.496 156.044 251.96 156.044H283.269C284.736 156.044 285.933 157.244 285.933 158.708C285.933 160.173 284.733 161.373 283.269 161.373Z" fill="#99BBEB"></path>
      <path d="M305.694 190.82H229.533C228.681 190.82 227.987 190.124 227.987 189.275C227.987 188.426 228.684 187.729 229.533 187.729H305.694C306.545 187.729 307.239 188.426 307.239 189.275C307.239 190.124 306.545 190.82 305.694 190.82Z" fill="#99BBEB"></path>
      <path d="M305.694 198.009H229.533C228.681 198.009 227.987 197.312 227.987 196.463C227.987 195.612 228.684 194.917 229.533 194.917H305.694C306.545 194.917 307.239 195.614 307.239 196.463C307.239 197.312 306.545 198.009 305.694 198.009Z" fill="#99BBEB"></path>
      <path d="M305.694 205.197H229.533C228.681 205.197 227.987 204.501 227.987 203.651C227.987 202.8 228.684 202.106 229.533 202.106H305.694C306.545 202.106 307.239 202.802 307.239 203.651C307.239 204.501 306.545 205.197 305.694 205.197Z" fill="#99BBEB"></path>
      <path d="M295.041 135.344H240.184C238.661 135.344 237.423 134.106 237.423 132.583V82.0196C237.423 80.4965 238.661 79.2588 240.184 79.2588H295.041C296.564 79.2588 297.801 80.4965 297.801 82.0196V132.583C297.803 134.104 296.564 135.344 295.041 135.344ZM240.186 81.5029C239.908 81.5029 239.672 81.7388 239.672 82.0173V132.581C239.672 132.859 239.908 133.095 240.186 133.095H295.043C295.321 133.095 295.557 132.859 295.557 132.581V82.0196C295.557 81.741 295.321 81.5052 295.043 81.5052H240.186V81.5029Z" fill="#99BBEB"></path>
      <path d="M248.101 168.325L249.689 171.544L253.24 172.059L250.671 174.566L251.277 178.101L248.101 176.432L244.925 178.101L245.531 174.566L242.962 172.059L246.513 171.544L248.101 168.325Z" fill="#99BBEB"></path>
      <path d="M261.108 168.325L262.696 171.544L266.25 172.059L263.678 174.566L264.284 178.101L261.108 176.432L257.932 178.101L258.539 174.566L255.969 172.059L259.52 171.544L261.108 168.325Z" fill="#99BBEB"></path>
      <path d="M274.118 168.325L275.706 171.544L279.257 172.059L276.687 174.566L277.294 178.101L274.118 176.432L270.942 178.101L271.548 174.566L268.979 172.059L272.53 171.544L274.118 168.325Z" fill="#99BBEB"></path>
      <path d="M287.125 168.325L288.713 171.544L292.264 172.059L289.694 174.566L290.301 178.101L287.125 176.432L283.949 178.101L284.555 174.566L281.986 172.059L285.537 171.544L287.125 168.325Z" fill="#99BBEB"></path>
      <path d="M224.128 40.5898H90.7563V259.22H224.128V40.5898Z" fill="#99BBEB"></path>
      <path d="M219.746 45.0444H97.7417V250.888H219.746V45.0444Z" fill="white"></path>
      <path d="M147.372 88.2803C147.286 88.0242 145.15 75.7635 144.894 70.9944C144.638 66.2254 152.556 65.5447 152.556 65.5447C152.556 65.5447 159.027 62.0539 163.71 63.842C168.393 65.6301 171.58 68.638 173.707 72.3849C175.837 76.1319 171.834 87.8085 171.834 87.8085L147.372 88.2803Z" fill="#2E2D3D"></path>
      <path d="M132.693 127.675C132.664 127.473 133.239 122.648 133.262 122.452C134.34 112.292 146.864 109.432 159.362 109.432C174.044 109.432 184.006 112.119 185.594 122.679C185.641 122.989 186.488 127.825 186.445 128.151C159.231 128.526 132.693 127.675 132.693 127.675Z" fill="#D5E1E8"></path>
      <path d="M161.882 117.321C160.426 119.469 159.359 119.559 157.78 117.321C156.525 115.542 152.331 111.485 152.331 109.434L154.052 88.9856C154.052 86.9346 155.729 85.2566 157.78 85.2566H161.882C163.932 85.2566 165.61 86.9346 165.61 88.9856L166.41 109.434C166.407 111.485 163.187 115.396 161.882 117.321Z" fill="#F09959"></path>
      <path d="M161.882 85.2588H157.78C155.73 85.2588 154.052 86.9368 154.052 88.9878L152.558 107.127C157.163 108.594 163.38 105.613 166.129 102.331L165.608 88.9878C165.608 86.9368 163.932 85.2588 161.882 85.2588Z" fill="#E38540"></path>
      <path d="M145.759 110.926L152.334 123.079C152.334 123.079 155.853 120.183 156.192 120.751C156.534 121.32 155.568 128.483 155.568 128.483C155.568 128.483 163.4 128.589 163.742 128.25C164.083 127.908 162.151 120.468 162.947 120.468C163.742 120.468 166.132 122.625 166.132 122.625L173.472 110.926L171.859 110.533L159.833 117.005L146.97 110.674L145.759 110.926Z" fill="#BDCCD4"></path>
      <path d="M153.277 116.526L158.158 119.801L156.646 128.497L163.229 128.131L161.585 120.222L165.633 116.526L163.73 114.734H155.177L153.277 116.526Z" fill="#D54A41"></path>
      <path d="M152.673 104.969L146.635 110.715C146.635 110.715 152.787 121.657 153.129 121.657C153.47 121.657 158.349 115.623 158.349 115.623L161.473 115.569L166.41 120.749L172.147 110.6L166.298 104.4C166.298 104.4 161.302 114.051 160.395 114.278C159.488 114.505 159.481 114.619 158.746 114.051C158.012 113.482 152.673 104.969 152.673 104.969Z" fill="#D5E1E8"></path>
      <path d="M159.831 102.356C166.676 102.356 172.225 94.794 172.225 85.4657C172.225 76.1373 166.676 68.5752 159.831 68.5752C152.986 68.5752 147.437 76.1373 147.437 85.4657C147.437 94.794 152.986 102.356 159.831 102.356Z" fill="#F09959"></path>
      <path d="M148.704 85.2051C149.863 87.6469 149.483 90.2527 147.857 91.0255C146.231 91.7982 143.971 90.4459 142.812 88.0041C141.653 85.5623 142.033 82.9565 143.659 82.1837C145.288 81.411 147.545 82.7633 148.704 85.2051Z" fill="#F09959"></path>
      <path d="M170.397 85.2051C169.238 87.6469 169.617 90.2527 171.244 91.0255C172.87 91.7982 175.129 90.4459 176.288 88.0041C177.447 85.5623 177.068 82.9565 175.442 82.1837C173.815 81.411 171.558 82.7633 170.397 85.2051Z" fill="#F09959"></path>
      <path d="M165.329 92.3955C165.329 92.3955 162.868 96.3828 159.831 96.3828C156.794 96.3828 154.333 92.3955 154.333 92.3955H165.329Z" fill="white"></path>
      <path d="M152.558 73.9754C157.666 70.3992 159.966 70.9944 163.796 76.871C167.627 82.7452 172.225 82.8508 172.225 82.8508L172.82 76.7586C172.82 76.7586 167.113 65.7177 159.452 67.1644C151.79 68.611 147.433 75.5097 147.433 75.5097C147.433 75.5097 147.06 88.4375 148.129 88.8778C149.198 89.3181 149.322 84.7063 150.087 83.7696C150.853 82.8328 149.322 78.4052 150.344 76.361C151.368 74.3168 152.558 73.9754 152.558 73.9754Z" fill="#2E2D3D"></path>
      <path d="M168.991 80.6179C170.774 82.57 170.098 90.3245 171.035 90.2391C171.971 90.1538 172.313 82.8306 172.057 82.0646C171.801 81.2986 168.991 80.6179 168.991 80.6179Z" fill="#2E2D3D"></path>
      <path d="M187.402 154.384H130.468C128.467 154.384 126.829 152.747 126.829 150.745C126.829 148.744 128.467 147.106 130.468 147.106H187.402C189.404 147.106 191.041 148.744 191.041 150.745C191.041 152.744 189.404 154.384 187.402 154.384Z" fill="#99BBEB"></path>
      <path d="M180.318 165.567H137.552C135.551 165.567 133.913 163.929 133.913 161.927C133.913 159.926 135.551 158.288 137.552 158.288H180.318C182.319 158.288 183.956 159.926 183.956 161.927C183.956 163.929 182.319 165.567 180.318 165.567Z" fill="#99BBEB"></path>
      <path d="M210.946 205.79H106.924C105.763 205.79 104.812 204.84 104.812 203.679C104.812 202.517 105.763 201.567 106.924 201.567H210.948C212.109 201.567 213.06 202.517 213.06 203.679C213.06 204.84 212.109 205.79 210.946 205.79Z" fill="#99BBEB"></path>
      <path d="M210.946 215.607H106.924C105.763 215.607 104.812 214.657 104.812 213.495C104.812 212.334 105.763 211.384 106.924 211.384H210.948C212.109 211.384 213.06 212.334 213.06 213.495C213.06 214.657 212.109 215.607 210.946 215.607Z" fill="#99BBEB"></path>
      <path d="M210.946 225.423H106.924C105.763 225.423 104.812 224.473 104.812 223.312C104.812 222.15 105.763 221.2 106.924 221.2H210.948C212.109 221.2 213.06 222.15 213.06 223.312C213.06 224.473 212.109 225.423 210.946 225.423Z" fill="#99BBEB"></path>
      <path d="M196.398 129.605H121.472C119.619 129.605 118.112 128.097 118.112 126.244V57.1838C118.112 55.3306 119.619 53.8232 121.472 53.8232H196.398C198.251 53.8232 199.758 55.3306 199.758 57.1838V126.246C199.758 128.097 198.251 129.605 196.398 129.605ZM121.472 56.0696C120.859 56.0696 120.358 56.5683 120.358 57.1838V126.246C120.358 126.859 120.857 127.36 121.472 127.36H196.398C197.011 127.36 197.512 126.862 197.512 126.246V57.1838C197.512 56.5706 197.013 56.0696 196.398 56.0696H121.472Z" fill="#99BBEB"></path>
      <path d="M123.26 175.064L125.427 179.46L130.279 180.163L126.768 183.587L127.597 188.417L123.26 186.137L118.92 188.417L119.749 183.587L116.238 180.163L121.09 179.46L123.26 175.064Z" fill="#99BBEB"></path>
      <path d="M141.026 175.064L143.196 179.46L148.046 180.163L144.537 183.587L145.364 188.417L141.026 186.137L136.687 188.417L137.516 183.587L134.007 180.163L138.857 179.46L141.026 175.064Z" fill="#99BBEB"></path>
      <path d="M158.793 175.064L160.963 179.46L165.812 180.163L162.304 183.587L163.133 188.417L158.793 186.137L154.456 188.417L155.285 183.587L151.774 180.163L156.623 179.46L158.793 175.064Z" fill="#99BBEB"></path>
      <path d="M176.56 175.064L178.73 179.46L183.581 180.163L180.071 183.587L180.9 188.417L176.56 186.137L172.223 188.417L173.052 183.587L169.541 180.163L174.393 179.46L176.56 175.064Z" fill="#99BBEB"></path>
      <path d="M194.61 175.064L196.78 179.46L201.629 180.163L198.121 183.587L198.949 188.417L194.61 186.137L190.273 188.417L191.101 183.587L187.591 180.163L192.44 179.46L194.61 175.064Z" fill="#99BBEB"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="422" height="320" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LkBack'
}
</script>
